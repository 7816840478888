import axios from 'axios';

export const postContactMessage = async (data: object) => {
    const apiHost = process.env.REACT_APP_API_HOST;
    const apiUrl = `${apiHost}/api/v1/contact`;

    try {
        const response = await axios.post(apiUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }

    // return new Promise((resolve, reject) => {
    //     // Simulate an asynchronous operation using setTimeout
    //     setTimeout(() => {
    //         resolve({});
    //     }, 2000);
    // });
};
